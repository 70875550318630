<template>
	<div class="container">
		<div class="mainTitle2 animationBlockFadeUp">
			<div class="mainTitle2__titleWithLink" v-if="content.link">
				<p class="h2" v-html="content.title"></p>
				<router-link class="mainTitle2--link hide600" :to="content.link" v-html="content.linkText" v-if="content.link"></router-link>
			</div>
			<p class="h2" v-html="content.title" v-else></p>

			<p class="mainTitle2--desc color-gray" v-html="content.description"></p>
		</div>

		<VueSlickCarousel class="infoBlocksSlider" v-if="isMobile && sliderInMobile" v-bind="slickSettings">
			<template v-for="item in content.items">
				<router-link class="animationBlockFadeUp" v-if="item.link" :to="item.link">
					<div class="infoBlocks__item" @mouseenter="mouseEnter($event)" @mouseleave="mouseLeave($event)" @touchstart="touchStart($event)" @touchend="touchEnd($event)">
						<div class="infoBlocks__up">
							<p class="infoBlocks--title" v-html="item.title"></p>
							<p class="infoBlocks--description" v-html="item.description" v-if="item.description"></p>
						</div>
						<div class="infoBlocks__down" v-if="item.projects">
							<div class="infoBlocks__images" v-if="item.projects">
								<div class="infoBlocks__images__inner">
									<div v-for="project in item.projects" v-html="project"></div>
								</div>
							</div>
						</div>
					</div>
				</router-link>
				<div class="animationBlockFadeUp" v-else>
					<div class="infoBlocks__item" @mouseenter="mouseEnter($event)" @mouseleave="mouseLeave($event)" @touchstart="touchStart($event)" @touchend="touchEnd($event)">
						<div class="infoBlocks__up">
							<p class="infoBlocks--title" v-html="item.title"></p>
							<p class="infoBlocks--description" v-html="item.description" v-if="item.description"></p>
						</div>
						<div class="infoBlocks__down" v-if="item.projects">
							<div class="infoBlocks__images" v-if="item.projects">
								<div class="infoBlocks__images__inner">
									<div v-for="project in item.projects" v-html="project"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
			<template #customPaging="page">
				<div class="dot"></div>
			</template>
		</VueSlickCarousel>

		<div class="infoBlocks" v-else>
			<template v-for="item in content.items">
				<router-link class="animationBlockFadeUp" v-if="item.link" :to="item.link">
					<div class="infoBlocks__item" @mouseenter="mouseEnter($event)" @mouseleave="mouseLeave($event)" @touchstart="touchStart($event)" @touchend="touchEnd($event)">
						<div class="infoBlocks__up">
							<p class="infoBlocks--title" v-html="item.title"></p>
							<p class="infoBlocks--description" v-html="item.description" v-if="item.description"></p>
						</div>
						<div class="infoBlocks__down" v-if="item.projects">
							<div class="infoBlocks__images" v-if="item.projects">
								<div class="infoBlocks__images__inner">
									<div v-for="project in item.projects" v-html="project"></div>
								</div>
							</div>
						</div>
					</div>
				</router-link>
				<div class="animationBlockFadeUp" v-else>
					<div class="infoBlocks__item" @mouseenter="mouseEnter($event)" @mouseleave="mouseLeave($event)" @touchstart="touchStart($event)" @touchend="touchEnd($event)">
						<div class="infoBlocks__up">
							<p class="infoBlocks--title" v-html="item.title"></p>
							<p class="infoBlocks--description" v-html="item.description" v-if="item.description"></p>
						</div>
						<div class="infoBlocks__down" v-if="item.projects">
							<div class="infoBlocks__images" v-if="item.projects">
								<div class="infoBlocks__images__inner">
									<div v-for="project in item.projects" v-html="project"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>

		</div>

		<router-link class="mainTitle2--link show600 mt32" :to="content.link" v-html="content.linkText" v-if="content.link"></router-link>
	</div>
</template>


<style lang="sass">
	@import '@/assets/new_sass/InfoBlocks'
</style>

<script>
	import VueSlickCarousel from 'vue-slick-carousel'
	import 'vue-slick-carousel/dist/vue-slick-carousel.css'
	import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

	export default {
		props: ['content', 'sliderInMobile'],
		data: () => ({
			animation: null,
			isMobile: null,
			slickSettings: {
				dots: true,
				arrows: false,
				dotsClass: 'infoBlocksSlider__dots slick-dots',
				infinite: true
			}
		}),
		mounted(){
			var comp = this;
			window.addEventListener('resize', function(event){
				comp.setIsMobile();
			});
			comp.setIsMobile();
		},
		methods: {
			setIsMobile: function(){
				if(window.innerWidth > 600){
					this.isMobile = false;
				} else{
					this.isMobile = true;
				}
			},
			animateMarquee: function(el, duration) {
				var comp = this;
				const parent = el.closest(".infoBlocks__item");
				const innerEl = el.querySelector('.infoBlocks__images__inner');
				const innerWidth = innerEl.offsetWidth;
				const cloneEl = innerEl.cloneNode(true);

				if(innerWidth > parent.offsetWidth) {
					el.appendChild(cloneEl);
					
					let start = performance.now();
					let progress;
					let translateX;
	
					comp.animation = requestAnimationFrame(function step(now) {
						progress = (now - start) / (duration / 700 * innerWidth);
				 
						if (progress > 1) {
							progress %= 1;
							start = now;
						}
	
						translateX = innerWidth * progress;
						
						innerEl.style.transform = `translate3d(-${translateX}px, 0 , 0)`;
						cloneEl.style.transform = `translate3d(-${translateX}px, 0 , 0)`;
						comp.animation = requestAnimationFrame(step);
					});
				}
			},
			mouseEnter: function(event){
				if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
					var target = event.target;
					var item = target.getElementsByClassName("infoBlocks__images");
					if(item.length){
						var element = item[0];
						this.animateMarquee(element, 9000);
					}
				}
			},
			mouseLeave: function(event){
				if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
					var target = event.target;

					if(this.animation){
						cancelAnimationFrame(this.animation);
						this.animation = null;
						var elements = target.getElementsByClassName("infoBlocks__images__inner");
						elements.forEach( function(element, index) {
							if(index == 0){
								element.style.transform = '';
							} else{
								element.parentNode.removeChild(element);
							}
						});
					}
				}
			},
			touchStart: function(event){
				if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
					var target = event.target;
					var item = target.getElementsByClassName("infoBlocks__images");
					if(item.length){
						var element = item[0];
						this.animateMarquee(element, 9000);
					}
				}
			},
			touchEnd: function(event){
				if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
					var target = event.target;

					if(this.animation){
						cancelAnimationFrame(this.animation);
						this.animation = null;
						var elements = target.getElementsByClassName("infoBlocks__images__inner");
						elements.forEach( function(element, index) {
							if(index == 0){
								element.style.transform = '';
							} else{
								element.parentNode.removeChild(element);
							}
						});
					}
				}
			},
		},
		components: {
			VueSlickCarousel
		}
	}
</script>